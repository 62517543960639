import { call, select, takeEvery } from 'redux-saga/effects';
import { postJSON, params as setParams } from '../../../shared/util-fetch';

export function* consent() {
    const { host, params } = yield select(state => state.api);
    const { logged_in, member_uuid } = yield select(state => state.user);

    if (logged_in && member_uuid) {
        const url = `${host}/member/member/${member_uuid}/privacy_policy`;

        yield call(postJSON, url, setParams({
            partner: params.partner,
            key: params.key,
        }));
    }
}

export function* root() {
    yield takeEvery('member:privacy-policy-consent', consent);
}
