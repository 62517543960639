export const initial = {
    price: null,
    submitting: false,
    submitted: false,
    subscription: null,
    type: null,
    upsells: [],
    view: 1,
    report_slug: null,
};

export const update = (state, payload) => ({
    ...state,
    ...payload,
});
