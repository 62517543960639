export const getQueryParams = () => {
    const queryString = window.location.search;

    return queryString
        ? (/^[?#]/.test(queryString) ? queryString.slice(1) : queryString).split('&').reduce((params, param) => {
            const [key, val] = param.split('=');
            params[key] = val ? decodeURIComponent(val.replace(/\+g/, '')) : '';
            return params;
        }, {})
        : {};
};

export const initial = getQueryParams();
