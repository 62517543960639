import dayjs from 'dayjs';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import { getJSON } from '../../shared/util-fetch';

export function* fetch(placement, product_result_slug) {
    const api = yield select(state => state.api);
    let data;

    try {
        data = yield call(getJSON, `${api.host}/rules/voucher-ownership/promo`, {
            ...api.params,
            site: api.params.partner,
            placement: placement,
            product_result_slug: product_result_slug,
            today: dayjs().format('YYYY-MM-DD'),
        });
    } catch (e) {
        return;
    }

    if (data && data.length) {
        const payload = data[Math.floor(Math.random() * data.length)];
        payload.placement = placement;

        yield put({
            type: 'product-message:update',
            payload: {
                [placement]: payload,
            },
        });
    }
}

export function* watchPlacement(payload) {
    while (true) {
        yield all([
            call(fetch, payload.payload.placement, payload.payload.product_result_slug),
            take(['user:logged-in', 'user:logged-out']),
        ]);
    }
}

export function* root() {
    yield takeLatest('product-message:require', watchPlacement);
}
