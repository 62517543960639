import { all, put, select, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { getJSON, patchJSON, postJSON } from '../../../shared/util-fetch';
import { states } from '../../../shared/util';
import _ from 'underscore';

export function* saveProfile({ payload }) {
    const { logged_in } = yield select(state => state.user);

    if (payload && logged_in) {
        const api = yield select(state => state.api);
        const url = `${api.host}/member/profile`;
        const params = yield call(getParams, payload);

        if (payload.profile_uuid) { //saving existing profile
            yield call(patchJSON, `${url}/${payload.profile_uuid}`, params);
        } else { //saving new profile
            yield call(postJSON, `${url}?partner=${api.params.partner}&key=${api.params.key}`, params);
        }

        yield put({
            type: 'user:force-refresh',
            payload: {
                profiles: true,
            },
        });
    }
}

export function* updateProfile({ payload }) {
    const { logged_in, member_uuid } = yield select(state => state.user);

    if (logged_in && member_uuid) {
        const api = yield select(state => state.api);
        const url = `${api.host}/member/member/${member_uuid}`;
        const params = yield call(getParams, payload);

        yield call(patchJSON, url, params);

        yield put({
            type: 'user:force-refresh',
            payload: {
                profiles: true,
            },
        });
    }
}

/* eslint-disable complexity */
function* getParams(payload) {
    const [location, api, view] = yield all([
        select(state => state.location),
        select(state => state.api),
        select(state => state.product && state.product.view),
    ]);

    const prefix = view && view == 2 ? 'person_2' : 'person_1';

    let params = {
        partner: api.params.partner,
        key: api.params.key,
    };

    if (payload.gender) {
        params.gender = payload.gender;
    }

    if (payload[`${prefix}_birth_date`]) {
        params.birth_date = payload[`${prefix}_birth_date`];
    }

    if (payload[`${prefix}_birth_time`]) {
        params.birth_time = payload[`${prefix}_birth_time`];
    }

    if (payload[`${prefix}_birth_location`] && payload[`${prefix}_birth_location`].country) {
        if (isNaN(payload[`${prefix}_birth_location`].country)) {
            params.birth_country = payload[`${prefix}_birth_location`].country;
        } else {
            params.birth_country = location.birth_country.three_letter_code;
        }

        if (params.birth_country == 'USA' && payload[`${prefix}_birth_location`].state) {
            let birth_state = null;

            if (isNaN(payload[`${prefix}_birth_location`].state)) {
                birth_state = payload[`${prefix}_birth_location`].state;
            } else {
                if (!location.birth_states) {
                    const birth_states_url = `/api/location/states/${payload[`${prefix}_birth_location`].country}`;
                    const birth_states = yield call(getJSON, birth_states_url);
                    birth_state = birth_states[payload[`${prefix}_birth_location`].state];
                } else {
                    birth_state = location.birth_states[payload[`${prefix}_birth_location`].state];
                }
            }

            birth_state = _.invert(states.USA)[birth_state];

            params.birth_state = birth_state;
        }

        if (payload[`${prefix}_birth_location`].city) {
            params.birth_city = payload[`${prefix}_birth_location`].city.replace(/ \(.*\)/, '');
        }

        if (payload[`${prefix}_birth_location`].city_id) {
            params.birth_city_id = payload[`${prefix}_birth_location`].city_id;
        }
    }

    if (payload[`${prefix}_location`] && payload[`${prefix}_location`].country) {
        if (isNaN(payload[`${prefix}_location`].country)) {
            params.current_country = payload[`${prefix}_location`].country;
        } else {
            params.current_country = location.current_country.three_letter_code;
        }

        if (params.current_country == 'USA' && payload[`${prefix}_location`].state) {
            let current_state = null;

            if (isNaN(payload[`${prefix}_location`].state)) {
                current_state = payload[`${prefix}_location`].state;
            } else {
                if (!location.current_states) {
                    const current_states_url = `/api/location/states/${payload[`${prefix}_location`].country}`;
                    const current_states = yield call(getJSON, current_states_url);
                    current_state = current_states[payload[`${prefix}_location`].state];
                } else {
                    current_state = location.current_states[payload[`${prefix}_location`].state];
                }
            }

            current_state = _.invert(states.USA)[current_state];

            params.current_state = current_state;
        }

        if (payload[`${prefix}_location`].city) {
            params.current_city = payload[`${prefix}_location`].city.replace(/ \(.*\)/, '');
        }

        if (payload[`${prefix}_location`].city_id) {
            params.current_city_id = payload[`${prefix}_location`].city_id;
        }
    }

    if (payload[`${prefix}_name`]) {
        if (payload[`${prefix}_name`].first) {
            params.first_name = payload[`${prefix}_name`].first;
        }

        if (payload[`${prefix}_name`].last) {
            params.last_name = payload[`${prefix}_name`].last;
        }
    }

    if (payload.name) {
        params.profile_name = payload.name;
        params.user_name = payload.name;
    }

    return params;
}
/* eslint-enable complexity */

export function* getProfiles({ payload }) {
    const user = yield select(state => state.user);

    if (user.logged_in) {
        const profiles = yield call(getJSON, '/api/user/profiles', payload);
        yield put({
            type: 'user:update',
            payload: {
                profiles: profiles,
            },
        });
    }
}

export function* getDefaultProfile() {
    const user = yield select(state => state.user);

    if (user.logged_in && _.isEmpty(user.profiles)) {
        const profile = yield call(getJSON, '/api/user/profile');
        yield put({
            type: 'user:update',
            payload: {
                profiles: [profile],
            },
        });
    }
}

export function* root() {
    yield all([
        takeEvery('member:save-profile', saveProfile),
        takeEvery('member:update-profile', updateProfile),
        takeEvery('member:get-profiles', getProfiles),
        takeLatest('member:get-default-profile', getDefaultProfile),
    ]);
}
