import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { params, postJSON } from '../../shared/util-fetch';
import { waitForPendingProduct } from './product';

export function* updatePayment({ payload }) {
    yield put({
        type: 'checkout:update',
        payload: {
            submitting: true,
        },
    });

    try {
        yield call(postJSON, '/api/member/subscription/update_payment', params({
            nonce: payload.nonce,
            remote_key: payload.remote_key,
        }));
        yield put({
            type: 'checkout:update',
            payload: {
                submitting: false,
                success: true,
            },
        });
    } catch (e) {
        yield put({
            type: 'checkout:update',
            payload: {
                submitting: false,
                success: false,
                error: e.body.error,
            },
        });
    }
}

export function* init(upc) {
    yield all([
        put({
            type: 'price:required',
            payload: { upc: upc },
        }),
        put({ type: 'cordial:browse' }),
    ]);
}

export function* submit({ payload }) {
    const [ upc, user ] = yield all([
        select(state => state.product.description && state.product.description.code),
        select(state => state.user),
    ]);

    const code = upc || payload;

    if (!user.logged_in) {
        yield all([
            put({
                type: 'product:update',
                payload: {
                    pending: true,
                },
            }),
            put({
                type: 'route:to',
                payload: 'login',
            }),
            take('user:logged-in'),
        ]);
    }

    yield call(waitForPendingProduct);

    if (code) {
        yield put({
            type: 'product:update',
            payload: {
                submitting: true,
            },
        });

        try {
            const order = yield call(postJSON, `/api/product/submit/${code}`);
            yield put({
                type: 'location:href',
                payload: order.forwarding_url,
            });
        } catch(e) {
            yield put({
                type: 'product:update',
                payload: {
                    error: 'Sorry, an error occurred',
                    submitting: false,
                },
            });
        }
    }
}

export function* root() {
    yield takeEvery('subscription:update-payment', updatePayment);
    const { payload: upc } = yield take('subscription:init');
    yield call(init, upc);
    yield takeEvery('product:submit', submit);
}
