import { select, all, call, put, takeEvery } from 'redux-saga/effects';
import _ from 'underscore';
import { getJSON, patchJSON, postJSON, params } from '../../../shared/util-fetch';

/* eslint-disable complexity */
export function* updateSettings() {
    const [user, settings] = yield all([
        select(state => state.user),
        select(state => state.settings),
    ]);

    if (user.logged_in && user.member_uuid && settings.fields) {
        let error = false;

        delete settings.errors.general;

        yield put({
            type: 'settings:update',
            payload: {
                errors: settings.errors,
                error: settings.errors.length,
                submitting: true,
            },
        });

        if (settings.fields.old_password && settings.fields.new_password) {
            try {
                yield call(postJSON, '/api/member/password-reset', {
                    old_password: settings.fields.old_password,
                    new_password: settings.fields.new_password,
                });
            } catch (e) {
                error = true;
                yield put({
                    type: 'settings:update',
                    payload: {
                        errors: {
                            new_password: e.body.error,
                        },
                        error: 1,
                    },
                });
            }
        }

        if (!error) {
            const api = yield select(state => state.api);
            let fields = settings.fields;

            if (!fields.sexual_orientation && user.sexual_orientation) {
                fields.sexual_orientation = user.sexual_orientation;
            }

            fields.partner = api.params.partner;
            fields.key = api.params.key;

            if (fields.email && fields.email != user.email) { //no error, attempt to update email first
                try {
                    yield call(patchJSON, `${api.host}/member/member/${user.member_uuid}`, {
                        email: fields.email,
                        key: fields.key,
                        partner: fields.partner,
                    });
                } catch (err) {
                    error = true;

                    yield put({
                        type: 'settings:update',
                        payload: {
                            errors: {
                                email: err.body.detail.fields.email[0],
                            },
                            error: 1,
                        },
                    });
                }
            }

            if (!error && Object.keys(fields).length) { //no error, update everything else
                try {
                    yield call(patchJSON, `${api.host}/member/member/${user.member_uuid}`, fields);
                } catch (err) {
                    error = true;
                    yield put({
                        type: 'settings:update',
                        payload: {
                            errors: {
                                general: err,
                            },
                            error: 1,
                        },
                    });
                }
            }

            if (!error) {
                yield all([
                    put({ type: 'member:get-details' }),
                    put({ type: 'user:force-refresh' }),
                    put({
                        type: 'settings:update',
                        payload: {
                            fields: {},
                            errors: {},
                            error: 0,
                            dirty: 0,
                            submitting: false,
                            success: true,
                        },
                    }),
                ]);
            } else {
                yield put({ type: 'settings:update', payload: { submitting: false }});
            }
        } else {
            yield put({ type: 'settings:update', payload: { submitting: false }});
        }
    }
}
/* eslint-enable complexity */

export function* getDetails() {
    yield put({ type: 'user:login-check' });

    const response = yield call(getJSON, '/api/member/get-details');

    if (response && response.status && response.status == 'ok' && response.details) {
        yield put({
            type: 'user:update',
            payload: response.details,
        });
    }

    yield put({ type: 'user:force-refresh' });
}

export function* passwordResetInit({ payload }) {
    yield put({
        type: 'form:update',
        payload: {
            submitting: true,
            submitted: false,
            error: null,
        },
    });

    try {
        yield call(postJSON, '/api/member/password-reset/init', params(payload));
    } catch (e) {
        yield put({
            type: 'form:update',
            payload: {
                submitting: false,
                error: e.body.error,
            },
        });

        return;
    }

    yield put({
        type: 'form:update',
        payload: {
            submitting: false,
            submitted: true,
        },
    });
}

export function* passwordResetSet({ payload }) {
    yield put({ type: 'form:update', payload: {
        submitting: true,
        submitted: false,
        error: null,
    }});

    try {
        yield call(postJSON, '/api/member/password-reset/set', params({
            key: payload.key,
            password: payload.password,
        }));
    } catch (e) {
        yield put({ type: 'form:update', payload: {
            submitting: false,
            error: e.body.error,
        }});

        return;
    }

    yield put({ type: 'form:update', payload: {
        submitting: false,
        submitted: true,
    }});
}

export function* getTransactions({ payload }) {
    const response = yield call(getJSON, '/api/member/get-transactions', payload);

    yield put({
        type: 'transactions:update',
        payload: response,
    });
}

export function* root() {
    yield all([
        takeEvery('member:update-settings', updateSettings),
        takeEvery('member:get-details', getDetails),
        takeEvery('member:password-reset-init', passwordResetInit),
        takeEvery('member:password-reset-set', passwordResetSet),
        takeEvery('member:get-transactions', getTransactions),
    ]);
}
