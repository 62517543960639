import dayjs from 'dayjs';
import { all, call, delay, put, select, take, takeEvery } from 'redux-saga/effects';
import { getJSON } from '../../shared/util-fetch';
import { MediaQuery as MQ } from '../../shared/media-query';

const blacklisted_pages = [
    '/confirm',
];

export function* fetch(uuid, placement, tags) {
    const [api, stored] = yield all([
        select(state => state.api),
        select(state => state.cookie.promo || {}),
    ]);
    const promo_ext = stored.order_promo_external || undefined;
    const promo_int = stored.order_promo_internal || undefined;

    let params = {
        ...api.params,
        mobile: typeof MQ.isMobile === 'function' && MQ.isMobile(),
        site: 'www.tarot.com',
        placement: placement,
        today: dayjs().format('YYYY-MM-DD'),
    };

    if (tags) {
        params['tags[]'] = tags;
    }

    if (promo_ext) {
        params.order_promo_ext = promo_ext.value;
    }

    if (promo_int && promo_int.expires > 1) {
        params.order_promo_int = promo_int.value;
    }

    yield delay(250);
    const data = yield call(getJSON, `${api.host}/rules/promo-message`, params);

    if (placement == 'notification' && data && data.length) {
        const payload = data.map(obj => ({ ...obj, uuid: uuid }));

        yield put({
            type: 'promo-message:update',
            payload: {
                [uuid]: payload,
            },
        });
    } else if (data && data.length) {
        const payload = data[Math.floor(Math.random() * data.length)];
        payload.placement = placement;
        payload.uuid = uuid;

        if (tags) {
            payload.tags = tags;
        }

        yield put({
            type: 'promo-message:update',
            payload: {
                [uuid]: payload,
            },
        });
    } else {
        yield put({
            type: 'promo-message:update',
            payload: {
                [uuid]: {
                    placement: placement,
                    uuid: uuid,
                    message: null,
                },
            },
        });
    }
}

export function* watchPlacement({ payload }) {
    while (true) {
        yield all([
            call(fetch, payload.uuid, payload.placement, payload.tags),
            take(['user:logged-in', 'user:logged-out']),
        ]);
    }
}

export function* root() {
    if (blacklisted_pages.indexOf(location.pathname) >= 0) {
        return;
    }

    yield all([
        takeEvery('promo-message:require', watchPlacement),
    ]);
}
