/*global pushly*/
import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { getJSON, putJSON } from '../../../shared/util-fetch';
import { getCookie } from '../cookie';

export function* login({ payload }) {
    try {
        yield call(putJSON, '/api/user/login', payload);
    } catch (e) {
        if (e.body && e.body.error_code) {
            if (e.body.error_code == 'member not found') {
                try {
                    yield call(getJSON, '/api/user/validate-email', { email: payload.email });

                    yield all([
                        put({
                            type: 'ga:event',
                            payload: [
                                'sign_up',
                                'Modal_Login-Signup',
                            ],
                        }),
                        put({
                            type: 'user:update',
                            payload: {
                                email: payload.email,
                                password: payload.password,
                            },
                        }),
                        put({
                            type: 'route:to',
                            payload: 'signup',
                        }),
                    ]);
                } catch (e) {
                    yield put({
                        type: 'user:update',
                        payload: {
                            emailError: e.body.error.email,
                        },
                    });
                }
            } else if (e.body.error_code == 'invalid password') {
                yield put({
                    type: 'user:update',
                    payload: {
                        passwordError: true,
                    },
                });
            } else if (e.body.error_code == 'member disabled' || e.body.error == 'member disabled') {
                yield put({
                    type: 'user:update',
                    payload: {
                        passwordError: 'The account associated with this email address account has been deleted. ' +
                            'Please contact support@tarot.com for further assistance.',
                    },
                });
            }
        } else {
            yield put({
                type: 'route:to',
                payload: '',
            });
        }
    }

    yield put({ type: 'user:refresh' });

    const { payload: user } = yield take('user:update');

    if (user && user.logged_in) {
        // Add birthdate and sign to pushly (no effect or error if not subscribed to pushly)
        pushly('profile', {
            birth_date: user.birth_date,
            sign: user.default_sign,
        });

        yield all([
            put({
                type: 'cookie:set',
                payload: {
                    name: 'LUID',
                    value: 1,
                },
            }),
            put({
                type: 'ga:event',
                payload: [
                    'sign_up',
                    'Modal_Login',
                ],
            }),
        ]);

        const pending = yield select(state => state.product.pending);
        if (pending) {
            yield put({
                type: 'product:update',
                payload: {
                    pending: false,
                },
            });
        }

        yield put({
            type: 'user:logged-in',
            payload: user,
        });

        yield put({
            type: 'ga:set-user',
            payload: user.member_uuid,
        });

        const route = yield select(state => state.route);

        if (route === 'login' || route === 'signup' || route === 'login-signup') {
            yield put({
                type: 'route:to',
                payload: '',
            });
        }
    }
}

export function* logout() {
    yield call(putJSON, '/api/user/logout');
    yield put({ type: 'cookie:remove', payload: 'LUID' });
    yield put({ type: 'user:logged-out' });
}

export function* loggedOut() {
    const path = window.location.pathname;

    if (path === '/checkout') {
        yield put({ type: 'location:href', payload: '/readings-reports' });
    } else if (path.match(/^\/member\//)) {
        yield put({ type: 'location:href', payload: '/' });
    }
}

export function* logoutHomeLogin() {
    yield call(putJSON, '/api/user/logout');
    yield put({ type: 'cookie:remove', payload: 'LUID' });

    window.location.href = '/#login';
}

export function* loginViaPassword({ payload }) {
    try {
        yield call(putJSON, '/api/user/login', payload);
    } catch (e) {
        if (e.body && e.body.error_code && e.body.error_code == 'invalid password') {
            yield put({
                type: 'user:update',
                payload: {
                    passwordError: true,
                },
            });
        }
    }

    const user = yield select(state => state.user);

    if (user && !user.passwordError) {
        yield all([
            put({
                type: 'user:update',
                payload: {
                    auto_login: false,
                },
            }),
            put({
                type: 'cookie:set',
                payload: {
                    name: 'LUID',
                    value: 1,
                },
            }),
        ]);
    }
}

function* loginCheck() {
    const user = yield select(state => state.user);

    if (user.logged_in) {
        // LUID stands for Logged-in Unique ID, which for now is just a boolean
        const luid = getCookie('LUID');

        if (!luid) {
            yield all([
                put({
                    type: 'user:update',
                    payload: {
                        logged_in: false,
                    },
                }),
                put({
                    type: 'location:href',
                    payload: '/#login',
                }),
            ]);
        }
    }
}

export function* watchLOLI() {
    while (true) {
        yield take(['user:logged-in', 'user:logged-out']);
        let user = yield select(state => state.user);

        const lolis = document.querySelectorAll('.is-lo, .is-li');

        if (lolis && lolis.length && user.logged_in) {
            lolis.forEach(loli => {
                loli.classList.remove('is-user-lo');
                loli.classList.add('is-user-li');
            });
        } else if (lolis && lolis.length && !user.logged_in) {
            lolis.forEach(loli => {
                loli.classList.remove('is-user-li');
                loli.classList.add('is-user-lo');
            });
        }
    }
}

export function* root() {
    yield all([
        takeEvery('user:login', login),
        takeEvery('user:logout', logout),
        takeEvery('user:logged-out', loggedOut),
        takeEvery('user:login-via-password', loginViaPassword),
        takeEvery('user:logout-home-login', logoutHomeLogin),
        takeEvery('user:login-check', loginCheck),
        fork(watchLOLI),
    ]);
}
