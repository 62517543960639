import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { deleteJSON, getJSON } from '../../../shared/util-fetch';

function* deleteEntry({ payload }) {
    const user = yield select(state => state.user);

    if (user && user.logged_in && payload.slug) {
        const response = yield call(deleteJSON, '/api/member/delete-journal', payload);

        if (response && response.code && response.code == 400) {
            yield put({ type: 'journal:update', payload: { error: 'An error has occurred. Please contact support' }});
        } else {
            const journal = yield call(getJSON, '/api/member/get-journal', { fresh: true });
            journal.error = null;

            yield put({ type: 'journal:update', payload: journal });

            yield put({ type: 'route:to', payload: '' });
        }
    }
}

function* get({ payload }) {
    const user = yield select(state => state.user);

    if (user && user.logged_in) {
        try {
            const response = yield call(getJSON, '/api/member/get-journal', payload);
            response.loaded = true;
            yield put({ type: 'journal:update', payload: response });
        } catch(e) {
            // don't update journal, journal.loaded if error
        }

    }
}

export function* root() {
    yield all([
        takeEvery('journal:delete', deleteEntry),
        takeEvery('journal:get', get),
    ]);
}