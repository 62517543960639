import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { params, postJSON } from '../../../shared/util-fetch';
import _ from 'underscore';

/* eslint-disable complexity */
export function* signupApi({ payload }) {
    const api = yield select((state) => state.api);
    const product = yield select((state) => state.product);
    const mailLists = yield select((state) => state['mail-lists']);
    const extra_params = yield select((state) => {
        const promo = state.cookie && state.cookie.promo || {};
        const external = promo.signup_promo_external && promo.signup_promo_external.value;
        const internal = promo.signup_promo_internal && promo.signup_promo_internal.value;
        return {
            signup_promo_external: external || null,
            signup_promo_internal: internal || null,
            signup_property_id: state.property_id,
        };
    });

    yield put({
        type: 'user:update',
        payload: {
            submitting: true,
        },
    });

    try {
        yield call(
            postJSON,
            api.host + '/member/signup?' + params(api.params),
            { ...payload, ...extra_params }
        );

        let route = 'sms';

        if (product && product.type && !payload.sms_optin) {
            route = '';
        }

        if (mailLists && mailLists.type && mailLists.type == 'Newsletter') {
            route = '';
        }

        yield put({ type: 'user:refresh' });

        const { payload: user } = yield take('user:update');

        yield put({ type: 'user:logged-in', payload: user });

        yield put({
            type: 'cookie:set',
            payload: {
                name: 'LUID',
                value: 1,
            },
        });

        yield put({
            type: 'ga:event',
            payload: [
                'sign_up',
                'Modal_Signup',
            ],
        });

        if (payload.birth_location) {
            yield take('user:update');

            yield put({
                type: 'member:update-profile',
                payload: {
                    person_1_birth_location: payload.birth_location,
                },
            });

            yield put({
                type: 'user:force-refresh',
                payload: {
                    profiles: true,
                },
            });
        }

        yield put({ type: 'route:to', payload: route });

        if (payload.privacy_policy) {
            yield all([
                put({
                    type: 'cookie:set',
                    payload: {
                        name: 'PRIVACY_POLICY_CONSENT',
                        value: 1,
                    },
                }),
                put({
                    type: 'cookie:set',
                    payload: {
                        name: 'POLICY_CONSENT_SHOWN',
                        value: 1,
                    },
                }),
                put({
                    type: 'member:privacy-policy-consent',
                }),
            ]);
        }

        if (!payload.sms_optin) {
            yield put({
                type: 'product:update',
                payload: {
                    pending: false,
                },
            });
        }
    } catch (e) {
        const error = get_error_messages(e.body.detail || null);

        yield all([
            put({
                type: 'user:refresh',
            }),
            put({
                type: 'user:update',
                payload: {
                    ...error,
                    ...{
                        birth_date: payload.birth_date,
                        birth_name: payload.display_name,
                        submitting: false,
                    },
                },
            }),
            put({
                type: 'route:to',
                payload: error.emailError || error.passwordError ? 'login' : 'signup',
            }),
        ]);
    }
}
/* eslint-enable complexity */

const get_error_messages = (detail) => {
    let message = {};

    if (detail && detail.fields) {
        if (detail.fields.birth_date) {
            message.birthDateError = 'Please check your Birth Date.';
        }

        if (detail.fields.email) {
            message.emailError = detail.fields.email[0];
        }

        if (detail.fields.password) {
            message.passwordError = detail.fields.password[0];
        }

        message.generalError = 'Please fill out required fields.';
    } else {
        message.generalError = detail.message;
    }

    return message;
};

export function* root() {
    yield takeEvery('user:signup-api', signupApi);
}
