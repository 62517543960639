import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { getJSON } from '../../shared/util-fetch';

export function* getStates({ payload }) {
    if (payload) {
        const country = yield call(getJSON, `/api/location/country/${payload.country_id}`);
        const states = yield call(getJSON, `/api/location/states/${payload.country_id}`);

        if (Object.keys(states).length) {
            yield put({
                type: 'location:update',
                payload: {
                    [`${payload.prefix}_states`]: states,
                    [`${payload.prefix}_country`]: country,
                },
            });
        } else {
            yield all([
                put({
                    type: 'product:get-cities',
                    payload: {
                        prefix: payload.prefix,
                        country_id: payload.country_id,
                        state_id: null,
                    },
                }),
                put({
                    type: 'location:update',
                    payload: {
                        [`${payload.prefix}_country`]: country,
                        [`${payload.prefix}_states`]: null,
                    },
                }),
            ]);
        }
    }
}

export function* getCities({ payload }) {
    if (payload) {
        let url = `/api/location/cities/${payload.country_id}`;
        if (payload.state_id) {
            url = `${url}/${payload.state_id}`;
        }

        const cities = yield call(getJSON, url);

        yield put({
            type: 'location:update',
            payload: {
                [`${payload.prefix}_cities`]: cities,
            },
        });
    }
}

export function* getLocation({ payload }) {
    if (payload) {
        let url = null;

        if (payload.city_id) {
            url = `/api/location/city/${payload.city_id}`;
        } else if (payload.city_name && payload.state_name && payload.country_name) {
            url = `/api/location/city/${payload.city_name}/${payload.state_name}/${payload.country_name}`;
        }

        if (url) {
            let data;
            try {
                data = yield call(getJSON, url);
            } catch (e) {
                return;
            }
            const profile = yield select(state => state.user && state.user.selected_profile);

            if (data && Object.keys(data).length) {
                const new_profile = {
                    ...profile,
                    ...{
                        [`${payload.prefix}_country_id`]: data.country_id,
                        [`${payload.prefix}_state_id`]: data.state_id,
                        [`${payload.prefix}_city_id`]: data.city_id,
                    },
                };

                yield put({
                    type: 'user:update',
                    payload: { selected_profile: new_profile },
                });
            }
        }
    }
}

export function* getYourBirthChart() {
    const user = yield select(state => state.user);

    if (user && user.logged_in) {
        let data;
        try {
            data = yield call(getJSON, '/api/product/result/your-birth-chart');
        } catch (e) {
            /* These are mostly bad birth city data */
            data = { error: e };
        }

        yield put({ type: 'report:update', payload: data });
    }
}

function* getProductPending() {
    let productUpdate = yield take('product:update');
    return productUpdate.payload.pending;
}

// also imported by i-ching-reading.js, report.js and subscription.js
export function* waitForPendingProduct() {
    const [user_uuid, product_uuid] = yield all([
        select(state => state.user && state.user.member_uuid),
        select(state => state.product && state.product.member_uuid),
    ]);

    // only allow pending products to be submitted by the same user
    if (user_uuid && product_uuid && user_uuid != product_uuid) {
        // refresh page without the ?report param if user is not the same
        const { origin, pathname } = window.location;
        const newLocation = new URL(`${origin}${pathname}`);
        window.location.href = newLocation.href;

        return;
    }

    const pending = yield select(state => state.product.pending);
    if (pending) {
        const nextRoute = yield take('route:to');
        if (nextRoute.payload == 'sms') {
            //wait for sms modal close
            let productPending = true;
            while (productPending) {
                productPending = yield call(getProductPending);
            }
        }
    }
}

export function* root() {
    yield all([
        takeEvery('product:get-states', getStates),
        takeEvery('product:get-cities', getCities),
        takeEvery('product:get-location', getLocation),
        takeEvery('product:get-your-birth-chart', getYourBirthChart),
    ]);
}
