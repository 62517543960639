import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getJSON } from '../../shared/util-fetch';
import _ from 'underscore';

function* fetchDeck({ payload: code }) {
    const [current, spread] = yield all([
        select(state => state.deck && state.deck.code),
        select(state => state.spread),
    ]);

    if (code === current) {
        return;
    }

    const deck = yield call(getJSON, `/api/content/deck_small/${code}`);

    yield all([
        put({
            type: 'deck:update',
            payload: deck,
        }),
        put({
            type: 'spread:update',
            payload: !spread ? _.shuffle(deck.cards).map(card => card.index) : spread,
        }),
    ]);
}

function* updateSpread({ payload }) {
    const spread = yield select(state => state.spread);

    if (!spread) {
        return;
    }

    yield put({
        type: 'spread:update',
        payload: spread.map(key => key == payload ? 'picked' : key),
    });
}

export function* root() {
    yield takeLatest('deck:required', fetchDeck);
    yield takeEvery('deck:spread', updateSpread);
}
