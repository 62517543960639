import { call, put, takeEvery } from 'redux-saga/effects';
import { getJSON } from '../../shared/util-fetch';

export function* sendToChineseHoroscope({ payload }) {
    const response = yield call(getJSON, '/api/compat-meter/getchinese', {
        month: payload.month,
        day: payload.day,
        year: payload.year,
    });

    if (response && response.status && response.status == 'ok' && response.data.sign) {
        yield put({
            type: 'location:href',
            payload: `/astrology/chinese-zodiac/${response.data.sign.toLowerCase()}`,
        });
    }
}

export function* root() {
    yield takeEvery('zodiac:send-to-chinese-horoscope', sendToChineseHoroscope);
}
