export const sagas = [
    'catalog',
    'compatibility-chooser',
    'cookie',
    'cordial',
    'deck',
    'decks',
    'ga',
    'keen',
    'location',
    'member/email-unsub',
    'member/index',
    'member/journal',
    'member/privacy-policy-consent',
    'member/profile',
    'member/sms',
    'price',
    'product',
    'product-message',
    'profile-manager',
    'promo-message',
    'promo-tracker',
    'route',
    'service',
    'subscription',
    'transits',
    'user/index',
    'user/login',
    'user/signup-api',
    'zodiac',
];

export const bundle_sagas = [
    'articles',
    'autologin-link',
    'checkout',
    'hexagram',
    'i-ching-reading',
    'karmacoin-store',
    'member/email-preferences',
    'number',
    'report',
    'tarot-reading',
];
