import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import { getJSON } from '../../../shared/util-fetch';

function* refresh() {
    const data = yield call(getJSON, '/api/user/status');

    yield put({
        type: 'user:update',
        payload: data.user,
    });
}

function* forceRefresh({ payload }) {
    const data = yield call(getJSON, '/api/user/status?refresh');

    if (payload && payload.profiles) {
        data.user.profiles = yield call(getJSON, '/api/user/profiles', { refresh: true });
    }

    yield put({
        type: 'user:update',
        payload: data.user,
    });
}

function* watchRouting() {
    // get initial route, from page load
    let hash = yield select(state => state.route);

    while (true) {
        const user = yield select(state => state.user);

        if (hash === 'login' || hash === 'login-signup') {
            if (user.logged_in) {
                yield put({
                    type: 'route:to',
                    payload: '',
                });
            }
        } else if (hash === 'signup') {
            if (user.logged_in) {
                yield put({
                    type: 'route:to',
                    payload: '',
                });
            } else if (!user.email || !user.password) {
                yield put({
                    type: 'route:to',
                    payload: 'login',
                });
            }
        }

        // block until next time it changes, and update `hash` to new value
        ({ payload: hash } = yield take(['route:to', 'route:hashchange']));
    }
}

export function* root() {
    yield all([
        fork(watchRouting),
        takeEvery('user:refresh', refresh),
        takeEvery('user:force-refresh', forceRefresh),
    ]);
}
