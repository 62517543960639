import { all, select, put, call, takeEvery } from 'redux-saga/effects';
import { postJSON } from '../../shared/util-fetch';

export function* contactFormSubmit({ payload }) {
    const submitting = yield select(state => state.product.submitting);

    if (submitting) {
        return;
    }

    yield put({
        type: 'product:update',
        payload: {
            submitting: true,
        },
    });

    let json = {
        subject: payload.subject.value,
        name: payload.name.value,
        email: payload.email.value,
        phone: payload.phone.value,
        description: payload.message.value,
        page: '/service',
    };

    try {
        yield call(postJSON, '/api/ticket/create', json);

        yield all([
            put({
                type: 'product:update',
                payload: {
                    submitting: false,
                    submitted: true,
                },
            }),
            put({ type: 'route:to', payload: 'service' }),
        ]);
    } catch(e) {
        yield put({
            type: 'product:update',
            payload: {
                error: 'sorry, an error occurred',
                submitting: false,
            },
        });
    }
}

export function* root() {
    yield takeEvery('service:contact-form-submit', contactFormSubmit);
}
