import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { getJSON } from '../../shared/util-fetch';

/* eslint-disable complexity */
export function* fetchPrice({ payload }) {
    const { host, params } = yield select(state => state.api);

    if (!host || !params) {
        return;
    }

    let price = null;
    let stored = null;
    let qparams = null;

    try {
        [price, stored, qparams] = yield all([
            call(getJSON, `${host}/commerce/price/${payload.upc}`, params),
            select(state => state.cookie.promo || {}),
            select(state => state['query-params']),
        ]);
    } catch (e) {
        return;
    }


    const promo_ext = stored && stored.order_promo_external || undefined;
    const promo_int = stored && stored.order_promo_internal || undefined;

    let vparams = {
        partner: params.partner,
        key: params.key,
        placement: payload.placement || 'text-teeup-message',
        site: params.partner,
    };

    if (promo_ext) {
        /* if teeup of specific report, only send promo_ext if it matches the report */
        if (! promo_ext.value.includes('SVC-')
            ||
            (qparams.report && promo_ext.value.includes('SVC-' + qparams.report))) {
            vparams.order_promo_ext = promo_ext.value;
        }
    }

    if (qparams.report && promo_int && promo_int.value.includes(qparams.report)) {
        // TCA only one right now, make sure report matches to get report specific message
        vparams.order_promo_int = promo_int.value;
    }

    let promo = null;

    if (price.usd && price.usd.voucher_upc) {
        vparams.voucher__upc = price.usd.voucher_upc;
        promo = yield call(getJSON, `${host}/rules/voucher-ownership/promo`, vparams);
    }

    let kc_sale_message = null;
    if (promo && promo[0] && promo[0].message) {
        yield put({
            type: 'ga:view_promotion',
            payload: {
                placement: promo[0].placement,
                id: promo[0].code,
                name: `Promo:${promo[0].placement}-${promo[0].code}`,
            },
        });

        if (price.usd) {
            price.usd.message = promo[0].message;
        }

        if (price.kc) {
            price.kc.message = promo[0].message;
        }
    } else if (price.kc && price.kc.current > 0 && !price.kc.message && vparams.placement == 'text-teeup-message') {
        // Only retrieve this message if we do not already have a promo message for the product
        // It would be better to skip when member has enough kcs
        let kc_price = yield call(getJSON, `${host}/commerce/price/karmacoin-package-100`, params);
        if (kc_price && kc_price.usd && kc_price.usd.voucher_upc) {
            vparams.voucher__upc = kc_price.usd.voucher_upc;
            promo = yield call(getJSON, `${host}/rules/voucher-ownership/promo`, vparams);
            if (promo && promo[0] && promo[0].message) {
                kc_sale_message = {
                    message: promo[0].message,
                    promo: promo[0],
                };
            }
        }
    }

    yield put({
        type: 'product:update',
        payload: {
            price: price,
            kc_sale_message: kc_sale_message,
        },
    });
}
/* eslint-enable complexity */

export function* root() {
    yield takeLatest('price:required', fetchPrice);
}
