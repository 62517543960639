import { all, put, take, select, call, takeEvery } from 'redux-saga/effects';
import { getJSON } from '../../shared/util-fetch';
import dayjs from 'dayjs';

export function* report() {
    const user = yield select(state => state.user);
    const { base_url, path, hash, submitting, slant, type } = yield select(state => state['compatibility-chooser']);
    const url = base_url + path.name + '#!' + hash.name;
    const ga_type = type == 'chinese' ? 'Chinese' : `${slant.charAt(0).toUpperCase()}${slant.slice(1)}`;

    yield put({
        type: 'ga:event',
        payload: [
            'Click',
            `Compat_${ga_type}:${path.name}-${hash.name}`,
        ],
    });

    yield put({
        type: 'product:update',
        payload: {
            type: 'Report',
        },
    });

    if (!user.logged_in) {
        yield all([
            put({
                type: 'route:to',
                payload: 'login',
            }),
            take('user:logged-in'),
        ]);
    }

    if (submitting) {
        return;
    }

    yield all([
        put({
            type: 'compatibility-chooser:update',
            payload: {
                submitting: true,
            },
        }),
        put({
            type: 'location:href',
            payload: url,
        }),
    ]);
}

export function* setChineseSign({ payload: { which, date }}) {
    const mDate = dayjs(date);
    const month = mDate.format('MM');
    const day = mDate.format('DD');
    const year = mDate.format('YYYY');
    const sign = yield call(getJSON, `/api/compat-meter/getchinese?month=${month}&day=${day}&year=${year}`);

    if (sign.data.sign) {
        const payload = {
            [which]: { name: sign.data.sign.toLowerCase() },
        };

        yield put({ type: 'compatibility-chooser:update', payload: payload });
    }
}

export function* root() {
    yield all([
        takeEvery('compatibility-chooser:report', report),
        takeEvery('compatibility-chooser:setChineseSign', setChineseSign),
    ]);
}
