export const initial = {
    nav: null,
    featured_articles: null,
    hexagram: null,
};

export const update = (state, payload) => ({
    ...state,
    ...payload,
});
