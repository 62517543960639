import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getJSON } from '../../shared/util-fetch';

function* getTransits({ payload }) {
    let { profiles } = yield select(state => state.user);

    if (!profiles || !profiles[0].profile_uuid) {
        yield put({
            type: 'user:force-refresh',
            payload: {
                profiles: true,
            },
        });
    }

    ({ profiles } = yield select(state => state.user));

    let transits;

    try {
        transits = yield call(getJSON, '/api/product/get-transits', { date: payload });
    } catch (e) {
        return;
    }

    yield put({
        type: 'transits:update',
        payload: transits,
    });
}

function* getVipTransits({ payload }) {
    let transits;

    try {
        transits = yield call(getJSON, '/api/product/get-vip-transits', { date: payload });
    } catch (e) {
        return;
    }

    yield put({
        type: 'transits:update',
        payload: transits,
    });
}

export function* root() {
    yield takeLatest('transits:get-transits', getTransits);
    yield takeLatest('transits:get-vip-transits', getVipTransits);
}
