import _ from 'underscore';

export const initial = {
    cards: [],
    error: null,
    interps: null,
    positions: [],
    subject: null,
    button_text: 'reveal the meaning',
};

export const update = (state, payload) => ({
    ...state,
    ...payload,
});
