export const smoothScrollToElement = (el, delay = false) => {
    if (el && el.getBoundingClientRect()) {
        const timeout = delay ? 1000 : 0;

        setTimeout(() => {
            let scrollTop;

            const docTop = document.body.scrollTop || document.documentElement.scrollTop;

            const elTopBound = el.getBoundingClientRect().top;
            const elTopMargin = Math.max(parseInt(window.getComputedStyle(el).marginTop), 0);

            const headerElement = document.querySelector('header.is-sticky');
            let offset = headerElement ? headerElement.offsetHeight : 0;

            const otherStickyNavs = document.querySelectorAll('ebr-navigation, ulr-navigation, ascom-navigation, .article-menu'); // eslint-disable-line max-len
            otherStickyNavs.forEach((stickyNav) => {
                offset += stickyNav.offsetHeight;
            });

            scrollTop = Math.max(docTop + elTopBound - elTopMargin - offset, 0);

            window.scroll({ top: scrollTop, behavior: 'smooth' });
        }, timeout);
    }
};

// passive events
(() => {
    /* eslint-disable */
    let passiveSupported = false;

    window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
        get: () => {
            passiveSupported = true;
        },
    }));

    if (typeof onWheel !== 'undefined') {
        document.addEventListener('wheel', onWheel, passiveSupported ? { passive: true } : false);
    }

    if (typeof onMouseWheel !== 'undefined') {
        document.addEventListener('mousewheel', onMouseWheel, passiveSupported ? { passive: true } : false);
    }

    if (typeof onTouchStart !== 'undefined') {
        document.addEventListener('touchstart', onTouchStart, passiveSupported ? { passive: true } : false);
    }

    if (typeof onTouchMove !== 'undefined') {
        document.addEventListener('touchmove', onTouchMove, passiveSupported ? { passive: true } : false);
    }
})();
