import Cookies from 'js-cookie';
import { all, call, put, takeEvery } from 'redux-saga/effects';

const getOpts = (opts) => {
    return {
        ...(opts || {}),
        domain: '.tarot.com',
        secure: /^https:/.test(location),
        // path is set to '/' by default, and really shouldn't ever be anything different
    };
};

function* set({ payload: cookie }) {
    const opts = getOpts(cookie.opts);
    yield call([Cookies, Cookies.set], cookie.name, cookie.value, opts);
    yield put({
        type: 'cookie:update',
        payload: {
            [cookie.name]: cookie.value,
        },
    });
}

function* remove({ payload: name }) {
    const opts = getOpts();
    yield call([Cookies, Cookies.remove], name, opts);
    yield put({
        type: 'cookie:update',
        payload: {
            [name]: undefined,
        },
    });
}

export function getCookie(name) {
    return Cookies.get(name);
}

export function* root() {
    yield all([
        takeEvery('cookie:set', set),
        takeEvery('cookie:remove', remove),
    ]);
}
