import 'whatwg-fetch';

export const getJSON = (url, data = {}, options = {}) => {
    const args = {
        ...options,
        method: 'GET',
        credentials: 'include',
    };

    if (typeof data == 'object' && Object.keys(data).length) {
        url = `${url}?${params(data)}`;
    }

    return runFetch(url, args);
};

export const postJSON = (url, data = {}, options = {}) => {
    const args = {
        ...options,
        method: 'POST',
        body: data,
        credentials: 'include',
    };

    return runFetch(url, args);
};

export const patchJSON = (url, data = {}, options = {}) => {
    const args = {
        ...options,
        method: 'PATCH',
        body: params(data),
        credentials: 'include',
    };

    return runFetch(url, args);
};

export const putJSON = (url, data = {}, options = {}) => {
    const args = {
        ...options,
        method: 'PUT',
        body: params(data),
        credentials: 'include',
    };

    return runFetch(url, args);
};

export const deleteJSON = (url, data = {}, options = {}) => {
    const args = {
        ...options,
        method: 'DELETE',
        body: params(data),
        credentials: 'include',
    };

    return runFetch(url, args);
};

export const runFetch = (url, args, responseType = 'json') => {
    if (typeof args.body != 'undefined' && args.method != 'GET') {
        if (typeof args.body == 'string') {
            args.headers = {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            };
        } else if (typeof args.body == 'object') {
            args.body = JSON.stringify(args.body);
            args.headers = {
                'Content-Type': 'multipart/form-data',
            };
        }
    }

    const networkError = 'Network Failure. Please Try Again.';
    return fetch(url, args)
        .catch(error => { // handle fetch network failures
            error.response = {
                ok: false,
                body: {
                    error: '',
                    error_code: networkError,
                    status: 'error',
                },
                status: 0,
                statusText: networkError,
            };
            throw error;
        })
        .then(response => {
            if (!response.ok) {
                /* Error format:
                {
                    body: {
                        error: ""
                        error_code: "member not found"
                        status: "error"
                    }
                    ok: false
                    status: 400
                    statusText: ""
                }
                */
                return response.json().then(json => {
                    return Promise.reject({
                        status: response.status,
                        ok: false,
                        statusText: response.statusText,
                        body: json,
                    });
                });
            }

            if (responseType == 'json') {
                return response.json().catch(() => {
                    return {};
                });
            } else if (responseType == 'text') {
                return response.text();
            }

            return response;
        })
        .then(result => {
            return result;
        });
};

export const params = (obj) => {
    let string = '';

    Object.keys(obj).map(key => {
        if (obj[key] == null) {
            string = `${string}${key}=&`;
        } else if (typeof obj[key] == 'object') {
            Object.keys(obj[key]).map(k => {
                string = `${string}${key}=${encodeURIComponent(obj[key][k])}&`;
            });
        } else {
            string = `${string}${key}=${encodeURIComponent(obj[key])}&`;
        }
    });

    return string.replace(/&$/, '');
};