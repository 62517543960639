import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getJSON } from '../../shared/util-fetch';

function* fetchDecks() {
    const current = yield select(state => state.decks);

    if (current && current.length > 0) {
        return; /* only fetch once */
    }

    const decks = yield call(getJSON, '/api/content/decks_small');
    yield put({ type: 'decks:update', payload: decks });
}

export function* root() {
    yield takeLatest('decks:required', fetchDecks);
}
