import { call, select, put, all, takeEvery } from 'redux-saga/effects';
import { getJSON, putJSON, deleteJSON } from '../../../shared/util-fetch';

export function* saveCurrentLists() {
    const api = yield select(state => state.api.host);
    const member_uuid = yield select(state => state.user.member_uuid);
    const all_lists = yield call(getJSON, `${api}/mail/subscription/${member_uuid}`);
    const subscribed_lists = all_lists.filter(list => list.subscribed === true);

    yield put({
        type: 'email-unsub:update',
        payload: {
            subscribed: subscribed_lists,
        },
    });
}

export function* handleChange() {
    const code = yield select(state => state['email-unsub'].code);
    const route = yield select(state => state.route);

    if (route == 'opt-out') {
        yield call(updateList, code, false);
    } else if (route == 'opt-in') {
        if (code == 'reengage') {
            const old_lists = yield select(state => state['email-unsub'].subscribed);
            yield all(old_lists.map(list => call(updateList, list.email_list, true)));
        } else {
            yield call(updateList, code, true);
        }
    }
}

export function* updateList(code, subscribe) {
    const [api, member_uuid] = yield all([
        select(state => state.api.host),
        select(state => state.user.member_uuid),
    ]);
    const url = `${api}/mail/subscription/${member_uuid}/${code}`;

    try {
        yield call(subscribe ? putJSON : deleteJSON, url, {
            reason: 'email',
            location: document.location.href,
        });
    } catch (e) {
        yield put({
            type: 'email-unsub:update',
            payload: {
                error: `${subscribe ? 'Subscribe' : 'Unsubscribe'} Failed`,
            },
        });
    }
}

export function* root() {
    const should_run = yield select(state => state['email-unsub']);
    if (!should_run) {
        return;
    }

    if (yield select(state => state.route)) {
        yield put({
            type: 'route:to',
            payload: '',
        });
    }

    const code = yield select(state => state['email-unsub'].code);

    if (code == 'reengage') {
        yield call(saveCurrentLists);
    }

    yield takeEvery('route:hashchanged', handleChange);
}
