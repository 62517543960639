import { eventChannel } from 'redux-saga';
import { all, call, fork, put, take } from 'redux-saga/effects';
import { putJSON } from '../../shared/util-fetch';

function* to() {
    while (true) {
        const { payload: hash } = yield take('route:to');
        const newHash = hash ? (`#${hash}`) : ''; // clear the # if we can

        // using pushState because it doesn't scroll the window on empty hashes
        // and because it'll prevent an extra route:hashchanged action
        yield call(
            [window.history, window.history.pushState],
            '',
            '',
            window.location.pathname + window.location.search + newHash
        );

        // for redux-naive code
        if (hash === 'login' || hash === 'signup') {
            let event = document.createEvent('HTMLEvents');
            event.initEvent(`route: ${hash}`, true, false);
            document.dispatchEvent(event);
        }
    }
}

function hashChannel() {
    return eventChannel(emit => {
        window.addEventListener('hashchange', (event) => {
            emit(event);
        });

        return () => window.removeEventLister('hashchange');
    });
}

function* watch() {
    const channel = hashChannel();

    while (true) {
        const event = yield take(channel);
        let hash = event.newURL.match(/#(.*)$/);

        if (hash) {
            hash = hash[1];
        } else {
            // reload page, usually caused by browse back button
            location.reload();
        }

        if (hash === 'logout') {
            yield call(putJSON, '/api/user/logout');
            yield put({ type: 'cookie:remove', payload: 'LUID' });

            yield all([
                put({ type: 'user:logout' }),
                put({
                    type: 'route:to',
                    payload: '',
                }),
            ]);
        } else {
            yield put({
                type: 'route:hashchanged',
                payload: hash,
            });
        }
    }
}

export function* root() {
    yield fork(to);
    yield fork(watch);
}
