export const initial = {
    submitting: false,
    submitted: false,
    error: null,
    errors: {},
    success: false,
};

export const update = (state, payload) => ({
    ...state,
    ...payload,
});
