import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { deleteJSON } from '../../shared/util-fetch';
import _ from 'underscore';

export function* deleteProfile({ payload }) {
    const { logged_in } = yield select(state => state.user);

    if (payload && logged_in) {
        const api = yield select(state => state.api);
        const url = `${api.host}/member/profile/${payload}?partner=${api.params.partner}&key=${api.params.key}`;

        yield all([
            put({
                type: 'product:update',
                payload: {
                    submitting: true,
                },
            }),
            call(deleteJSON, url),
        ]);

        yield put({
            type: 'user:force-refresh',
            payload: {
                profiles: true,
            },
        });

        yield take('user:update');

        const { profiles } = yield select(state => state.user);
        const tab = profiles && profiles.length > 1 ? 'friend' : 'new';

        yield all([
            put({
                type: 'user:update',
                payload: {
                    tab: tab,
                    selected_profile: null,
                },
            }),
            put({
                type: 'route:to',
                payload: '',
            }),
            put({
                type: 'product:update',
                payload: {
                    submitting: false,
                },
            }),
        ]);
    }
}

export function* saveProfile({ payload }) {
    yield all([
        put({
            type: 'product:update',
            payload: {
                submitting: true,
            },
        }),
        put({
            type: 'member:save-profile',
            payload: payload,
        }),
    ]);

    yield take('user:update');

    if (payload.profile_uuid) { //saving existing profile
        yield all([
            put({
                type: 'user:update',
                payload: {
                    selected_profile: _.findWhere(yield select(state => state.user.profiles), {
                        profile_uuid: payload.profile_uuid,
                    }),
                },
            }),
            put({
                type: 'route:to',
                payload: '',
            }),
        ]);
    } else { //saving new profile
        const { profiles } = yield select(state => state.user);
        const profile = _.sortBy(profiles, 'created').reverse()[0];

        yield put({
            type: 'user:update',
            payload: {
                tab: 'friend',
                selected_profile: profile,
            },
        });
    }

    yield all([
        put({
            type: 'product:update',
            payload: {
                submitting: false,
            },
        }),
        put({
            type: 'form:update',
            payload: {
                success: true,
            },
        }),
    ]);
}

export function* updateProfile({ payload }) {
    yield all([
        put({
            type: 'product:update',
            payload: {
                submitting: true,
            },
        }),
        put({
            type: 'member:update-profile',
            payload: payload,
        }),
    ]);

    yield take('user:update');

    yield all([
        put({
            type: 'product:update',
            payload: {
                submitting: false,
            },
        }),
        put({
            type: 'form:update',
            payload: {
                success: true,
            },
        }),
    ]);
}

export function* root() {
    yield all([
        takeEvery('profile-manager:delete-profile', deleteProfile),
        takeEvery('profile-manager:save-profile', saveProfile),
        takeEvery('profile-manager:update-profile', updateProfile),
    ]);
}
