import { all, select, call, put, takeEvery } from 'redux-saga/effects';
import { putJSON } from '../../../shared/util-fetch';

export function* optIn({ payload }) {
    const user = yield select(state => state.user);
    const promos = yield select((state) => {
        const promo = state.cookie && state.cookie.promo || {};
        const external = promo.signup_promo_external && promo.signup_promo_external.value;
        const internal = promo.signup_promo_internal && promo.signup_promo_internal.value;
        return {
            signup_promo_external: external || null,
            signup_promo_internal: internal || null,
        };
    });

    if (user && user.logged_in && payload && payload.phone_number) {
        let sms = {
            error: null,
            view: 2,
        };

        try {
            yield call(putJSON, '/api/member/sms/opt-in', {
                phone_number: payload.phone_number,
                location: payload.location,
                signup_promo_external: promos.signup_promo_external,
                signup_promo_internal: promos.signup_promo_internal,
            });
        } catch (e) {
            sms.error = e.body.error;
            sms.view = 1;
        }

        if (!sms.error) {
            yield put({
                type: 'ga:event',
                payload: [
                    'sign_up',
                    'Modal_SMS',
                ],
            });
        }

        yield put({
            type: 'user:update',
            payload: {
                sms: sms,
            },
        });
    }
}

export function* optOut({ payload }) {
    const user = yield select(state => state.user);

    if (user && user.logged_in && payload && payload.location) {
        let sms = {};

        try {
            yield call(putJSON, '/api/member/sms/opt-out', {
                location: payload.location,
            });
            sms.unsubscribed = true;
        } catch (e) {
            sms.error = e.body.error;
        }

        yield put({
            type: 'sms:update',
            payload: sms,
        });
    }
}

export function* root() {
    yield all([
        takeEvery('sms:opt-in', optIn),
        takeEvery('sms:opt-out', optOut),
    ]);
}
