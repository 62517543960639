// this saga enables assertions against other sagas
import { fork, take } from 'redux-saga/effects';

function* watchHref() {
    const { payload: href } = yield take('location:href');
    window.location.href = href;
}

export function* root() {
    yield fork(watchHref);
}
