export const initial = {
    base_url: null,
    path: null,
    hash: null,
    submitting: false,
};

export const update = (state, payload) => ({
    ...state,
    ...payload,
});
