class MQ {
    constructor() {
        this.breakpoints = {
            tiny: 369,
            before_mobile: 599,
            mobile: 600,
            before_tablet_landscape: 899,
            tablet_landscape: 900,
            before_large: 1199,
            large: 1200,
        };
    }

    isTiny() {
        return window.matchMedia(`(max-width: ${this.breakpoints.tiny}px)`).matches;
    }

    isMobile() {
        return window.matchMedia(`(max-width: ${this.breakpoints.before_mobile}px)`).matches;
    }

    isTablet() {
        return window.matchMedia(`(min-width: ${this.breakpoints.mobile}px)`).matches
            && window.matchMedia(`(max-width: ${this.breakpoints.before_large}px)`).matches;
    }

    isTabletPortrait() {
        return window.matchMedia(`(min-width: ${this.breakpoints.mobile}px)`).matches
            && window.matchMedia(`(max-width: ${this.breakpoints.before_tablet_landscape}px)`).matches;
    }

    isTabletLandscape() {
        return window.matchMedia(`(min-width: ${this.breakpoints.tablet_landscape}px)`).matches
            && window.matchMedia(`(max-width: ${this.breakpoints.before_large}px)`).matches;
    }

    isLarge() {
        return window.matchMedia(`(min-width: ${this.breakpoints.large}px)`).matches;
    }

    isPortrait() {
        return window.matchMedia('(orientation: portrait)').matches;
    }

    isLandscape() {
        return window.matchMedia('(orientation: landscape)').matches;
    }
}

export const MediaQuery = new MQ();
