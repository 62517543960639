export const update = (state, payload) => {
    return {
        ...state,
        ...payload,
    };
};

export const logout = () => {
    return { logged_in: false };
};
