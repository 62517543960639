export const reducers = [
    'articles',
    'autologin-link',
    'catalog',
    'checkout',
    'compatibility-chooser',
    'content',
    'cookie',
    'deck',
    'decks',
    'email-unsub',
    'form',
    'hexagram',
    'i-ching',
    'journal',
    'karmacoin-store',
    'keen',
    'location',
    'mail-lists',
    'number',
    'product',
    'product-message',
    'promo-message',
    'query-params',
    'report',
    'route',
    'settings',
    'sms',
    'spread',
    'tarot-reading',
    'transactions',
    'transits',
    'zodiac',
    'ui',
    'user',
];
