/* eslint-disable no-useless-escape */
import { smoothScrollToElement } from './scroll';
import dayjs from 'dayjs';

export const hasTouchEvents = 'ontouchstart' in window || 'onmsgesturechange' in window;

export const nl2br = (text) => {
    if (typeof text === 'string') {
        return text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
    }

    return null;
};

export const slugify = (string) => {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string.toString().toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
        .replace(/\'/g, ''); // Remove single quotes '
};

export const inViewport = (selector, checkVisibility) => {
    const element = document.querySelector(selector);

    if (element) {
        const bounding = element.getBoundingClientRect();

        if (
            bounding.top >= 0
            &&
            bounding.left >= 0
            &&
            Math.round(bounding.right) <= (
                window.innerWidth
                ||
                document.documentElement.clientWidth
            )
            &&
            Math.round(bounding.bottom) <= (
                window.innerHeight
                ||
                document.documentElement.clientHeight
            )
            &&
            (!checkVisibility || element.offsetParent !== null)
        ) {
            return true;
        }
    }

    return false;
};

export const getBirthTime = (hour, minute, ampm) => {
    if (!isNaN(parseInt(hour, 10)) && !isNaN(parseInt(minute, 10))) {
        let birth_time_hour = hour;

        if (parseInt(hour, 10) == 12) {
            birth_time_hour = ampm == 'am' ? 0 : 12;
        } else if (ampm == 'pm'){
            birth_time_hour = parseInt(hour, 10) + 12;
        }

        return birth_time_hour.toString().padStart(2, 0) + ':' + minute + ':00';
    }

    return null;
};

export const getBirthDate = (month, day, year) => {
    if (
        !isNaN(parseInt(month, 10))
        &&
        !isNaN(parseInt(day, 10))
        &&
        !isNaN(parseInt(year, 10))
    ) {
        const birth_date = dayjs(
            `${year}-${month.toString().padStart(2, 0)}-${day.toString().padStart(2, 0)}`,
            'YYYY-MM-DD'
        ).format('YYYY-MM-DD');

        return birth_date;
    }

    return null;
};

export const states = {
    USA: {
        AL: 'Alabama',
        AK: 'Alaska',
        AR: 'Arkansas',
        AZ: 'Arizona',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        DC: 'Dist of Columbia',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        PR: 'Puerto Rico',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        WA: 'Washington',
        WI: 'Wisconsin',
        WV: 'West Virginia',
        WY: 'Wyoming',
    },
};

export const months = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
};

export const days = [...Array(32).keys()].slice(1);

export const asComProducts = [
    'astrology-compatibility-redux',
    'astrology-compatibility-sample',
    'relationship-potential-redux',
    'love-languages-redux',
    'relationship-dynamics-redux',
];

//use hashes that start with '!' to trigger smoothScrollToElement on page load
if (window.location.hash && window.location.hash.match(/\!/)) {
    const hash = window.location.hash.replace(/\!/, '');
    try {
        const target = document.querySelector(hash);

        if (target) {
            smoothScrollToElement(target, true);
        }
    } catch(e) {
        // ignore invalid hash
    }
}

/* eslint-enabled no-useless-escape */
export const isValidEmail = (email) => {
    /* eslint-disable max-len, no-useless-escape */
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    /* eslint-enable max-len, no-useless-escape */
    if (regex.test(email)) {
        return true;
    }

    return false;
};
/* eslint-enabled no-useless-escape */

export const gfxURL = 'https://gfx.tarot.com/images';

if (hasTouchEvents && window.innerWidth >= 900) {
    document.querySelectorAll('nav-bar-full a.nav-item:not(.psychic-advice)')
        .forEach(link => link.removeAttribute('href'));

    window.addEventListener('touchstart', (e) => {
        const activeItems = document.querySelectorAll('a.nav-item.active');
        if (e.target.classList.contains('nav-item') && e.target.classList.contains('active')) {
            document.location.href = e.target.dataset.href;
        } else if (e.target.classList.contains('nav-item')) {
            activeItems.forEach(a => a.classList.remove('active'));
            e.target.classList.add('active');
        } else {
            activeItems.forEach(a => a.classList.remove('active'));
        }
    });
}

export const checkLocationData = (states, value) => {
    return states && !Object.keys(states).includes('line') && !value;
};

export const signs = [
    {
        name: 'aries',
        start: 'Mar 21',
        end: 'Apr 19',
    },
    {
        name: 'taurus',
        start: 'Apr 20',
        end: 'May 20',
    },
    {
        name: 'gemini',
        start: 'May 21',
        end: 'Jun 20',
    },
    {
        name: 'cancer',
        start: 'Jun 21',
        end: 'Jul 22',
    },
    {
        name: 'leo',
        start: 'Jul 23',
        end: 'Aug 22',
    },
    {
        name: 'virgo',
        start: 'Aug 23',
        end: 'Sep 22',
    },
    {
        name: 'libra',
        start: 'Sep 23',
        end: 'Oct 22',
    },
    {
        name: 'scorpio',
        start: 'Oct 23',
        end: 'Nov 21',
    },
    {
        name: 'sagittarius',
        start: 'Nov 22',
        end: 'Dec 21',
    },
    {
        name: 'capricorn',
        start: 'Dec 22',
        end: 'Jan 19',
    },
    {
        name: 'aquarius',
        start: 'Jan 20',
        end: 'Feb 18',
    },
    {
        name: 'pisces',
        start: 'Feb 19',
        end: 'Mar 20',
    },
];

export const date_to_sign_name = (birthdate) => {
    // this is the day of each month that the sign changes over, jan 20, feb 19, etc
    const cusps = [0, 20, 19, 21, 20, 21, 21, 23, 23, 23, 23, 22, 22];
    const month = new Date(birthdate).getUTCMonth() + 1;
    const day = new Date(birthdate).getUTCDate();

    // Figure out the right month and return the adjusted offset based on whether
    //  we're before or after the sign changeover date.
    const offset = day < cusps[month] ? 3 : 2;
    // Add 11 (to make january=0), subtract offset, mod by 12 to get a number between 0-11,
    const int = ((month + 11 - offset) % 12);

    return signs[int].name || null;
};

export const capitalize = (string) => {
    return string.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
};

export const localize = (url) => {
    if (url && typeof url === 'string') {
        return url.replace(/http(s)?:\/\/www.tarot.com/, '');
    }

    return null;
};

export const toggleDOMClass = (list, className, method) => {
    if (list && className && (method === 'add' || method === 'remove')) {
        document.querySelectorAll(list).forEach(element => {
            element.classList && element.classList[method](className);
        });
    }
};