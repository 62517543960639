/* global require */
import { reducers } from './reducers.js';
import { sagas } from './sagas.js';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { h, render } from 'preact';
import { Provider } from 'react-redux';
import _ from 'underscore';

let initialData = {};
let rootReducers = {};

reducers.map(name => {
    rootReducers = { ...rootReducers, [name]: require(`./reducers/${name}`) };

    const reducerInitial = typeof rootReducers[name].initial === 'undefined' ? null : rootReducers[name].initial;

    initialData = { ...initialData, [name]: reducerInitial };
}, {});

const rootReducer = (state, action) => {
    if (!/:/.test(action.type)) {
        return { ...initialData, ...state };
    }

    const [reducerName, methodName] = action.type.split(':');
    const reducerMethod = rootReducers[reducerName] && rootReducers[reducerName][methodName];
    const reducerState = state[reducerName] || initialData[reducerName];
    const newReducerState = reducerMethod ? reducerMethod(reducerState, action.payload, action.error) : reducerState;

    return { ...state, [reducerName]: newReducerState };
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const initialState = window.appState || {};
const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(sagaMiddleware)));

export const registerSaga = (saga) => {
    sagaMiddleware.run(saga);
};

sagas.map(name => {
    const saga = require(`./sagas/${name}`).root;
    registerSaga(saga);
});

const attributesToObject = (attrs) => {
    return Array.prototype.reduce.call(attrs, (obj, attr) => {
        obj[attr.name] = attr.value;
        return obj;
    }, {});
};

const mountEachNode = (tag, Component) => {
    const nodes = document.querySelectorAll(tag);

    _.each(nodes, node => {
        if (node._componentConstructor) {
            return;
        }

        render(
            <Provider store={store}>
                <Component innerHTML={node.innerHTML} {...attributesToObject(node.attributes)} />
            </Provider>,
            node.parentNode,
            node
        );

        node._component = Component;
    });
};

export const register = (tag, Component) => {
    // mount to existing elements
    mountEachNode(tag, Component);

    // watch for new elements
    const observer = new MutationObserver(() => {
        mountEachNode(tag, Component);
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true,
    });
};
