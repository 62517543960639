import { postJSON } from '../../shared/util-fetch';
import { all, call, fork, select, put, take } from 'redux-saga/effects';

function* getBrowseEvent() {
    const [ desc, teeup ] = yield all([
        select(state => state.product.description),
        select(state => state.product.teeup),
    ]);

    if (desc) {
        return {
            category: desc.categories[0],
            product: desc.product_name,
            productID: desc.code,
            placement: 'teeup',
        };
    } else if (teeup) {
        return {
            category: teeup.product_type,
            product: teeup.display_name,
            productID: teeup.code,
            placement: 'module',
        };
    }

    return null;
}

export function* sendSavedBrowseEvents() {
    const events = yield select(state => state.cookie['cordial-events'] || []);

    yield put({ type: 'cookie:remove', payload: 'cordial-events' });

    yield all(events.slice(-5).map((single_event) =>
        call(sendEvent, single_event, 'browse')
    ));
}

export function* saveBrowseEvent(single_event) {
    const events = yield select(state => state.cookie['cordial-events'] || []);
    if (single_event) {
        if (events.push(single_event) > 5) {
            events.shift();
        }

        yield put({ type: 'cookie:set', payload: {
            name: 'cordial-events',
            value: events,
            opts: { expires: .042 }, // .042 = about 1 hour
        }});
    }
}

export function* sendEvent(single_event, action) {
    const [ user, impersonate ] = yield all([
        yield select(state => state.user),
        yield select(state => state.cookie.impersonate || null),
    ]);
    try {
        if (user.member_uuid && !impersonate) {
            try {
                yield call(postJSON, '/api/event', { event_type: action, event: single_event });
            } catch (e) {
                return;
            }
        }
    } catch(e) {
        return;
    }
}

export function* sendBrowseEvent(single_event) {
    yield call(sendEvent, single_event, 'browse');
}

export function* sendBrowsePageEvent(single_event) {
    yield call(sendEvent, single_event, 'browse-page');
}

export function* watchUser() {
    while (true) {
        const [ user, impersonate ] = yield all([
            yield select(state => state.user),
            yield select(state => state.cookie.impersonate || null),
        ]);
        if (user.member_uuid && !impersonate) {
            try {
                yield call(sendSavedBrowseEvents);
            } catch(e) {
                //continue
            }
        }

        yield take(['user:logged-in']);
    }
}

export function* watchBrowseEvents() {
    while (true) {
        yield take('cordial:browse');

        const [single_event, user] = yield all([
            call(getBrowseEvent),
            select(state => state.user),
        ]);

        if (single_event && user.member_uuid) {
            yield call(sendBrowseEvent, single_event);
        } else {
            yield call(saveBrowseEvent, single_event);
        }
    }
}

export function* watchBrowsePage() {
    while (true) {
        yield take('cordial:page');

        const tags = yield select(state => state.cordial && state.cordial.tags);

        if (tags) {
            const single_event = {
                url: document.location.href,
                title: document.title,
                tags: tags,
            };

            yield call(sendBrowsePageEvent, single_event);
        }
    }
}

export function* root() {
    yield all([
        fork(watchUser),
        fork(watchBrowseEvents),
        fork(watchBrowsePage),
    ]);
}
