import dayjs from 'dayjs';
import { all, call, select, put, takeEvery } from 'redux-saga/effects';

function* record(payload) {
    yield put({
        type: 'cookie:set',
        payload: {
            name: 'promo',
            value: payload,
            opts: { expires: 14 }, // days
        },
    });
}

export function* remove() {
    yield put({ type: 'cookie:remove', payload: 'promo' });
}

export const compact = (parts) => {
    return parts.map((part) => (
        // ensuring no newlines or other weird spacing
        part.trim().replace(/\s+/g, ' ')
    )).join(':');
};

// External promos are captured in Middleware

// internal promos preserve external promos, and are things like ga events and promo messages
export function* recordInternal({ payload }) {
    const stored = yield select(state => state.cookie.promo || {});
    const promo_value = compact(payload);

    if (stored.order_promo_internal && stored.order_promo_internal.sticky) {
        return; // Do not overwrite if "sticky"
    }

    yield call(record, {
        order_promo_external: stored.order_promo_external || undefined,
        order_promo_internal: { value: promo_value, expires: 10 },
        signup_promo_external: stored.signup_promo_external || undefined,
        signup_promo_internal: { value: promo_value, expires: 10 },
    });
}

// order_promo_external expires after 8 hours
// signup_promo_external expires after 10 pages
// order_promo_internal expires after 10 pages
// signup_promo_internal expires after 10 pages
export function* incrementAndExpire() {
    const new_cookie = {};
    const stored_cookie = yield select(state => state.cookie.promo || {});

    // expiration by time
    if (stored_cookie.order_promo_external) {
        const order_promo_external_expires = dayjs(stored_cookie.order_promo_external.expires);
        if (
            stored_cookie.order_promo_external.value
            &&
            order_promo_external_expires.isValid()
            &&
            order_promo_external_expires > dayjs()
        ) {
            new_cookie.order_promo_external = stored_cookie.order_promo_external;
        }
    }

    // expiration by page view
    ['order_promo_internal', 'signup_promo_external', 'signup_promo_internal'].forEach((type) => {
        if (stored_cookie[type] && stored_cookie[type].value && stored_cookie[type].expires) {
            new_cookie[type] = {
                value: stored_cookie[type].value,
                expires: stored_cookie[type].expires - 1,
            };

            if (stored_cookie[type].sticky) {
                new_cookie[type].sticky = stored_cookie[type].sticky;
            }
        }
    });

    if (Object.keys(new_cookie).length) {
        yield call(record, new_cookie);
    } else {
        yield call(remove);
    }
}

export function* root() {
    // fork action listeners
    yield all([
        takeEvery('promo-tracker:recordInternal', recordInternal),
        takeEvery('user:logged-out', remove),
    ]);

    // setup or check initial state
    yield call(incrementAndExpire);
}
